<template>
    <Section class="bg-white">
        <Container class="mb-10 bg-slate-50 lg:mb-20">
            <div class="flex flex-col items-center md:flex-row md:justify-between">
                <h2 class="text-center text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-start">
                    Noch weitere Fragen?<br />Wir stehen Ihnen zur Seite.
                </h2>
                <div class="mt-8 flex flex-col items-end md:mt-0">
                    <p
                        class="font-semibold md:text-lg"
                        :class="props.style === 'blue' ? 'text-sky-600' : 'text-lime-600'"
                    >
                        Kostenlose Beratung
                    </p>
                    <div class="flex items-center gap-2">
                        <PhoneIcon :class="props.style === 'blue' ? 'text-sky-700' : 'text-lime-600'" />
                        <a class="text-2xl font-bold text-sky-950 md:text-3xl" href="tel:+4922182829650">
                            +49 221 82829650
                        </a>
                    </div>
                    <p class="text text-end">Mo.- Fr. 8 bis 20 Uhr</p>
                </div>
            </div>
        </Container>
    </Section>
</template>

<script setup>
import Section from '@/Components/Fragments/BaseSection.vue'
import Container from '@/Components/Fragments/BaseContainer.vue'
import PhoneIcon from '@/Components/SVG/Phone.vue'

const props = defineProps({
    style: {
        type: String,
        default: 'blue',
    },
})
</script>
